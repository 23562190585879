export default {
  cake: {
    56: '0xb4c20Bb1C75300Fa724ec3196B5d1C854a7d58a0',
    97: '',
  },
  masterChef: {
    56: '0xaE14db04Dcc3158dDE825Ccc1AcC365A796Fd279',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  ysoy: {
    56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
    97: '',
  },
}
