import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1, // 40
    risk: 5,
    lpSymbol: 'ySOY-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x541b8508dd127159e39866daa4d9d4f30b47da61',
    },
    tokenSymbol: 'ySOY',
    tokenAddresses: {
      97: '',
      56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 7, // 20
    risk: 5,
    lpSymbol: 'yTOFU-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x70e38edb1616cd2bd8254389afef7d38362a3d41',
    },
    tokenSymbol: 'yTOFU',
    tokenAddresses: {
      97: '',
      56: '0xb4c20Bb1C75300Fa724ec3196B5d1C854a7d58a0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.cake,
  },

  {
    pid: 2, // 20
    risk: 5,
    lpSymbol: 'ySOY-yTOFU LP',
    lpAddresses: {
      97: '',
      56: '0x3d613a96f6700388c31bbb3d1fce22a2f86e5e00',
    },
    tokenSymbol: 'ySOY',
    tokenAddresses: {
      97: '',
      56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
    },
    quoteTokenSymbol: QuoteToken.YTOFU,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'ySOY',
    lpAddresses: {
      97: '',
      56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
    },
    tokenSymbol: 'yTOFU',
    tokenAddresses: {
      97: '',
      56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
    },
    quoteTokenSymbol: QuoteToken.YSOY,
    quoteTokenAdresses: contracts.ysoy,
  },

  // {
  //   pid: 8,
  //   risk: 5,
  //   isTokenOnly: true,
  //   lpSymbol: 'ySOY',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xC980cc5aE27B970056780c00F8427B11F28f1Ba7',
  //   },
  //   tokenSymbol: 'yTOFU',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
  //   },
  //   quoteTokenSymbol: QuoteToken.YSOY,
  //   quoteTokenAdresses: contracts.ysoy,
  // },

  // {
  //   pid: 4,
  //   risk: 5,
  //   lpSymbol: 'ySOY-LPO LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x10dfa5c5315c076ec5fabbab551644fb743502d0',
  //   },
  //   tokenSymbol: 'ySOY',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
  //   },
  //   quoteTokenSymbol: QuoteToken.LPO,
  //   quoteTokenAdresses: contracts.ysoy,
  // },

  
  // {
  //   pid: 5,
  //   risk: 5,
  //   lpSymbol: 'yTOFU-LPO LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x986516B8ce83B4b629C4EACA292ECa7ecd8733F8',
  //   },
  //   tokenSymbol: 'yTOFU',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xb4c20Bb1C75300Fa724ec3196B5d1C854a7d58a0',
  //   },
  //   quoteTokenSymbol: QuoteToken.LPO,
  //   quoteTokenAdresses: contracts.cake,
  // },

  // {
  //   pid: 6,
  //   risk: 5,
  //   lpSymbol: 'ySOY-YFBT LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa8c99a167defdde86766fa616ccd0ac46e63779d',
  //   },
  //   tokenSymbol: 'ySOY',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
  //   },
  //   quoteTokenSymbol: QuoteToken.YFBT,
  //   quoteTokenAdresses: contracts.cake,
  // },





  // {
  //   pid: 1,
  //   lpAddresses: {
  //     56: '0x541b8508dd127159e39866daa4d9d4f30b47da61', 
  //   },
  //   tokenAddresses: {
  //     56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42', 
  //   },
  //   name: 'ySoy-BNB',
  //   symbol: 'ySoy-BNB LP',
  //   tokenSymbol: 'ySoy',
  //   icon: ' ',
  // },

  // {
  //   pid: 2,
  //   lpAddresses: {
  //     56: '0x3d613a96f6700388c31bbb3d1fce22a2f86e5e00', 
  //   },
  //   tokenAddresses: {
  //     56: '0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42', 
  //   },
  //   name: 'ySoy-yTofu',
  //   symbol: 'ySoy-yTofu LP',
  //   tokenSymbol: 'yTofu',
  //   icon: ' ',
  // },


  // POOLS


  // {
  //   pid: 24,
  //   risk: 5,
  //   isTokenOnly: true,
  //   lpSymbol: 'yTOFU',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019', // EGG-BUSD LP
  //   },
  //   tokenSymbol: 'yTOFU',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
 
]

export default farms
