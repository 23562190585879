import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'About',
    icon: 'MoreIcon',
    href: '/about',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Liquidity',
        href: 'https://exchange.pancakeswap.finance/#/add/BNB/0x57488Fcc3dC72Edb0a4c06a356c2c43C08BdfB42',
      },
      // {
      //   label: 'Hotbit Ysoy/USDT',
      //   href: 'https://www.hotbit.io/exchange?symbol=YSOY_USDT',
      // },
      // {
      //   label: 'Dex-Trade yTOFU/USDT',
      //   href: 'https://dex-trade.com/spot/trading/YTOFUUSDT',
      // },
      // {
      //   label: 'Dex-Trade yTOFU/BTC',
      //   href: 'https://dex-trade.com/spot/trading/YTOFUBTC',
      // },

      // {
      //   label: 'BankCEX Ysoy/USDT',
      //   href: 'https://bankcex.com/exchange-base.html?symbol=YSOY_USDT',
      // },
      // {
      //   label: 'BankCEX Ysoy/BTC',
      //   href: 'https://bankcex.com/exchange-base.html?symbol=YSOY_BTC',
      // },
    ],
  },
  {
    label: 'Farm yTOFU',
    icon: 'FarmIcon',
    href: '/farms',
  },

  // {
  //   label: 'Farms coming soon',
  //   icon: 'FarmIcon',
  //   items: [
  //     {
  //       label: 'Farm ySoyMilk',
  //       href: '/',
  //     },
  //     {
  //       label: 'Farm ySoyBean Oil',
  //       href: '/',
  //     },
  //    ]
  // },
  {
    label: 'Stake Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Coingecko ySOY',
        href: 'https://www.coingecko.com/en/coins/ysoy-chain',
      },
      {
        label: 'ySOY',
        href: 'https://pancakeswap.info/token/0x57488fcc3dc72edb0a4c06a356c2c43c08bdfb42',
      },
      {
        label: 'yTOFU',
        href: 'https://pancakeswap.info/token/0xb4c20Bb1C75300Fa724ec3196B5d1C854a7d58a0',
      },
      {
        label: 'DAPP',
        href: 'https://www.dapp.com/app/ysoy-chain-farm',
      },
      {
        label: 'DAPP Review',
        href: 'https://dapp.review/dapp/ysoy-chain-farm',
      },
      {
        label: 'Github',
        href: 'https://github.com/ySoyFarm',
      },
      {
        label: 'Medium',
        href: 'https://ysoychain.medium.com/',
      },
    ],
  },
  {
    label: 'Whitepaper',
    icon: 'AuditIcon',
    items: [
       {
        label: 'Whitepaper EN',
        href: 'https://drive.google.com/file/d/1Q1kQkBSJeDIiyAgxvVIOgUI9C4PC_oFq/view',
      },
      {
        label: 'Whitepaper PT-BR',
        href: 'https://drive.google.com/file/d/19_bMljPXedhACyOeOMG8ucvK4-plCVit/view',
      },
    ]
  },

  {
    label: 'Ysoy Allocation',
    icon: 'ChartIcon',
    href: '/allocation',
  },

  {
    label: 'Ysoy Farm Fight',
    icon: 'NftIcon',
    href: 'https://fight.ysoychain.farm/',
  },

  {
    label: 'Coingecko ySOY',
    icon: 'CoingeckoIcon',
    href: 'https://www.coingecko.com/en/coins/ysoy-chain',
  },

  {
    label: 'Coingecko yTOFU',
    icon: 'CoingeckoIcon',
    href: 'https://www.coingecko.com/en/coins/ytofu',
  }

]

export default config
